var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',_vm._b({attrs:{"color":_vm.color}},'v-timeline-item',_vm.$attrs,false),[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-scroll-y-transition',[(
              (hover || _vm.deleting) &&
                (_vm.$root.acceso('DEV') ||
                  (_vm.$root.acceso('EDITAR_ESTUDIOS') &&
                    _vm.$store.getters.getTokenPayload.idUsuario ==
                      _vm.message.idUsuario &&
                    new Date().getTime() <=
                      Number(_vm.message.fecha) + 1000 * 60 * 5))
            )?_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","loading":_vm.deleting,"small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.eliminar.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-card-subtitle',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.message.Nombre)+" "),(_vm.$store.getters.getTokenPayload.idUsuario == _vm.message.idUsuario)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-account")]):_vm._e(),_vm._v(" - "+_vm._s(new Date(Number(_vm.message.fecha)).toLocaleString())+" "),(!Number(_vm.message.leido))?_c('v-icon',[_vm._v("mdi-circle-small")]):_vm._e()],1),_c('div',{staticClass:"px-4 py-1",domProps:{"innerHTML":_vm._s(_vm.message.mensaje)}}),(_vm.message.children.length > 0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.message.children),function(child){return _c('v-chip',{key:child.idMensaje,staticClass:"ma-1 mt-0",attrs:{"label":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.descargarArchivo(child.archivo)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(child.archivo.substring(child.archivo.indexOf("_") + 1))+" ")],1)}),1):_vm._e(),(_vm.$store.getters.getTokenPayload.idUsuario != _vm.message.idUsuario && _vm.message.leidoPorComercial && _vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('div',{staticClass:"float-right mt-n6 mr-3 "},[_c('v-icon',{attrs:{"color":"info","small":""}},[_vm._v("mdi-check-all")])],1):_vm._e()],1)]}}])}),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }