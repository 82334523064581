<template>
  <v-timeline-item v-bind="$attrs" :color="color">
    <v-hover v-slot="{ hover }">
      <v-card>
        <v-scroll-y-transition>
          <v-btn
            v-if="
              (hover || deleting) &&
                ($root.acceso('DEV') ||
                  ($root.acceso('EDITAR_ESTUDIOS') &&
                    $store.getters.getTokenPayload.idUsuario ==
                      message.idUsuario &&
                    new Date().getTime() <=
                      Number(message.fecha) + 1000 * 60 * 5))
            "
            @click.stop="eliminar"
            class="float-right"
            icon
						:loading="deleting"
            small
            color="error"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-scroll-y-transition>

        <v-card-subtitle class="py-2">
          {{ message.Nombre }}
          <v-icon
            small
            color="primary"
            v-if="$store.getters.getTokenPayload.idUsuario == message.idUsuario"
            >mdi-account</v-icon
          >
          -
          {{ new Date(Number(message.fecha)).toLocaleString() }}
          <!-- <status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" /> -->
          <v-icon
            v-if="!Number(message.leido)"
            >mdi-circle-small</v-icon
          >
        </v-card-subtitle>

        <div class="px-4 py-1" v-html="message.mensaje" />
        <div class="d-flex flex-wrap" v-if="message.children.length > 0">
          <v-chip
            label
            @click="descargarArchivo(child.archivo)"
            v-for="child in message.children"
            :key="child.idMensaje"
            color="primary"
            outlined
            class="ma-1 mt-0"
          >
            <v-icon left>mdi-download</v-icon>
            {{ child.archivo.substring(child.archivo.indexOf("_") + 1) }}
          </v-chip>
        </div>
        <div v-if="$store.getters.getTokenPayload.idUsuario != message.idUsuario && message.leidoPorComercial && $root.acceso('EDITAR_ESTUDIOS')" class="float-right mt-n6 mr-3 ">
          <v-icon color="info" small>mdi-check-all</v-icon>
        </div>
      </v-card>
    </v-hover>
        <ConfirmDialog ref="confirm" />
  </v-timeline-item>
</template>

<script>
import { downloadFile, parseDate } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  props: {
    message: Object,
  },
	data() {
		return {
			deleting: false
		}
	},
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  computed: {
    color() {
      let color;
      if (this.message.IdCarteraCobro) color = "primary";
      if (this.message.IdCliente) color = "secondary";
      return color;
    },
  },
  methods: {
    parseDate,
    descargarArchivo(name) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/mensajesEstudios.php`,
        responseType: "blob",
        params: {
          name,
        },
      }).then((res) => {
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File([res.data], name.substring(name.indexOf("_") + 1), {
          type: extensions[extension],
        });

        let download = true;
        if (["pdf", "png", "jpg", "jpeg", "gif"].includes(extension))
          download = false;

        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    async eliminar() {
      if (!await this.$refs.confirm.open('Eliminar mensaje', '¿Estás seguro de que quieres eliminar este mensaje?')) return;
      this.deleting = true;
      axios({
        method: "delete",
        url: `${process.env.VUE_APP_API_URL}/mensajesEstudios.php`,
        data: {
          idMensaje: this.message.idMensaje,
        },
      }).then((res) => {
        this.$emit("reload");
        this.deleting = false;
      });
    },
  },
};
</script>
